import { Box, Paper, Slide, useMediaQuery, useTheme } from "@mui/material";
import { useScreen } from "../../contexts/Screen/ScreenContext";
import NotesList from "../NotesList/NotesList";

export type SidebarProps = React.PropsWithChildren;

const Sidebar: React.FC<SidebarProps> = () => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("lg"));
  const { sidebar } = useScreen();

  if (isMobile) return (
    <Slide
      in={sidebar.isOpen || sidebar.isProminent}
      direction="right"
    >
      <Box
        sx={{
          position: "fixed",
          width: "100%",
          maxWidth: sidebar.isProminent ? "100%" : 320,
          height: "100vh",
          p: {
            xs: 2,
            lg: 3,
          },
          zIndex: theme => theme.zIndex.drawer,
        }}
      >
        <Paper
          sx={{
            height: "100%",
          }}
        >
          <NotesList />
        </Paper>
      </Box>
    </Slide>
  );

  return (
    <Box
      sx={{
        width: 320,
        height: "100vh",
        p: 3,
        pr: 0,
      }}
    >
      <Paper
        sx={{
          height: "100%",
        }}
      >
        <NotesList />
      </Paper>
    </Box>
  );
};

export default Sidebar;

import { TypographyProps } from "@mui/material";
import { createContext, useContext } from "react";

export type TypographyContextValue = TypographyProps;

export const TypographyContext = createContext({});

export const TypographyContextProvider: React.FC<TypographyContextValue> = ({
  children,
  ...props
}) => (
  <TypographyContext.Provider value={props}>
    {children}
  </TypographyContext.Provider>
);

export const useTypography = (): TypographyContextValue => useContext(TypographyContext);

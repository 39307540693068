import { BrowserRouter, Route, Routes } from "react-router-dom";
import Index from "./screens/Index/Index";
import ViewNote from "./screens/ViewNote/ViewNote";

const Router: React.FC = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/notes/:id" element={<ViewNote />} />
    </Routes>
  </BrowserRouter>
);

export default Router;

import React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { blackTheme } from "./styles/themes/black";
import { NotesContextProvider } from "./contexts/Notes/NotesContext";
import Router from "./Router";

const App: React.FC = () => (
  <NotesContextProvider>
    <ThemeProvider theme={blackTheme}>
      <CssBaseline />
      <Router />
    </ThemeProvider>
  </NotesContextProvider>
);

export default App;

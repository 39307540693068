import { Box, Grid } from "@mui/material";
import { ScreenContextProvider, ScreenContextProviderProps } from "../../contexts/Screen/ScreenContext";
import Sidebar from "../Sidebar/Sidebar";

export type ScreenProps = ScreenContextProviderProps;

const Screen: React.FC<ScreenProps> = ({ children, ...props }) => (
  <ScreenContextProvider {...props}>
    <Grid container>
      <Grid item>
        <Sidebar />
      </Grid>
      <Grid item xs>
        <Box
          sx={{
            height: "100vh",
            px: {
              xs: 0,
              md: 2,
              lg: 3,
            },
            py: 2,
            boxSizing: "border-box",
          }}
        >
          {children}
        </Box>
      </Grid>
    </Grid>
  </ScreenContextProvider>
);

export default Screen;

import { Container, Paper, Stack, Typography, useTheme } from "@mui/material";

const Splash: React.FC = () => {
  const { palette } = useTheme();
  const primary = palette.primary.main;
  const secondary = palette.secondary.main;

  return (
    <Container
      sx={{
        height: "100%",
        position: "relative"
      }}
    >
      <Stack
        sx={{
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          sx={{
            maxWidth: "100%",
            p: 4,
            backgroundColor: primary,
          }}
        >
          <Stack
            sx={{
              alignItems: "end",
              gap: 2,
            }}
          >
            <Typography
              variant="h1"
              sx={{
                color: palette.getContrastText(primary),
              }}
            >
              Scratchpad
            </Typography>
            <Paper
              elevation={0}
              sx={{
                px: 2,
                pt: 1,
                pb: 0.5,
                backgroundColor: secondary,
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  color: palette.getContrastText(secondary),
                }}
              >
                by Worksation Software
              </Typography>
            </Paper>
          </Stack>
        </Paper>
      </Stack>
    </Container>
  );
};

export default Splash;

import React from "react";
import Screen from "../../widgets/Screen/Screen";
import Splash from "../../widgets/Splash/Splash";

const Index: React.FC = () => (
  <Screen prominentSidebar>
    <Splash />
  </Screen>
);

export default Index;

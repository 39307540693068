import { Paper, PaperProps, ThemeProvider } from "@mui/material";
import { yellow } from "@mui/material/colors";
import { lightTheme } from "../../styles/themes/light";

export type SheetProps = PaperProps;

const Sheet: React.FC<SheetProps> = ({ sx, ...props }) => (
  <ThemeProvider theme={lightTheme}>
    <Paper
      sx={{
        backgroundColor: yellow[50],
        px: {
          xs: 2,
          sm: 3,
          md: 4,
        },
        py: 2,
        maxHeight: "100%",
        overflow: "auto",
        ...sx
      }}
      {...props}
    />
  </ThemeProvider>
);

export default Sheet;

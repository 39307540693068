import React, { createContext, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocalStorage } from "react-use";
import * as uuid from "uuid";

export interface Note {
  id: string;
  title: string;
  text: string;
}

export type Notes = Record<string, Note>;

export interface NotesContextValue {
  notes: Notes;
  setNotes: (notes: Notes) => void;
}

export const NotesContext = createContext<NotesContextValue>({
  notes: {},
  setNotes: () => {},
});

export const NotesContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [notes = {}, setNotes] = useLocalStorage("notes", {});

  return (
    <NotesContext.Provider value={{ notes, setNotes }}>
      {children}
    </NotesContext.Provider>
  );
};

export const useNotes = () => {
  const { notes, setNotes } = useContext(NotesContext);
  // const [currentNote, setCurrentNote] = useState<string | null>(null);

  const { id: currentNote } = useParams();
  const navigate = useNavigate();
  const setCurrentNote = (id?: string | null) => {
    if (id) navigate(`/notes/${id}`);
    else navigate("/");
  }

  const listNotes = () => Object.values(notes);

  const getNote = (id: string) => {
    const note = notes[id];
    if (!note) throw new Error("Note not found.");
    return note;
  };

  const createNote = () => {
    const note: Note = {
      id: uuid.v4(),
      title: "",
      text: "",
    };

    setNotes({
      ...notes,
      [note.id]: note,
    });

    setCurrentNote(note.id);

    return note;
  };

  const updateNote = (id: string, data: Partial<Note>) => {
    const note = getNote(id);
    setNotes({
      ...notes,
      [id]: {
        ...note,
        ...data,
      },
    });
  };

  const deleteNote = (id: string) => {
    setCurrentNote(null);
    const notesClone = { ...notes };
    delete notesClone[id];
    setNotes(notesClone);
  };

  return {
    notes,
    setNotes,
    currentNote,
    setCurrentNote,
    listNotes,
    getNote,
    createNote,
    updateNote,
    deleteNote,
  };
};

import { ThemeOptions } from "@mui/material";

export const sansSerifFonts = [
  "Atkinson Hyperlegible",
  "sans-serif",
];

export const monospaceFonts = [
  "Courier Prime",
  "monospace",
];

export const sansSerif = sansSerifFonts
  .map((font) => font.includes(" ") ? `"${font}"` : font)
  .join(", ");

export const monospace = monospaceFonts
  .map((font) => font.includes(" ") ? `"${font}"` : font)
  .join(", ");

export const typography: ThemeOptions = {
  typography: {
    fontFamily: sansSerif,
  },
};

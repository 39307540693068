import { Divider, Link, styled, Table, TableBody, TableCell, TableHead, TableRow, Typography, TypographyProps, TypographyVariant } from "@mui/material";
import { CodeComponent, HeadingComponent, SpecialComponents } from "react-markdown/lib/ast-to-react";
import { NormalComponents } from "react-markdown/lib/complex-types";
import { monospace } from "../../styles/typography";
import { TypographyContextProvider, useTypography } from "./TypographyContext";

// Taken from `ReatMarkdown` props.
export type Components = Partial<Omit<NormalComponents, keyof SpecialComponents> & SpecialComponents>;

export type HTMLComponent<T extends HTMLElement> = React.FC<React.HTMLProps<T>>;

const makeTypographyComponent = (variant: TypographyVariant) => ({
  sx,
  ...props
}: TypographyProps) => (
  <TypographyContextProvider variant={variant}>
    <Typography
      variant={variant}
      {...props}
      sx={{
        mt: 2,
        ...sx
      }}
    />
  </TypographyContextProvider>
);

export const H1: HeadingComponent = makeTypographyComponent("h1");
export const H2: HeadingComponent = makeTypographyComponent("h2");
export const H3: HeadingComponent = makeTypographyComponent("h3");
export const H4: HeadingComponent = makeTypographyComponent("h4");
export const H5: HeadingComponent = makeTypographyComponent("h5");
export const H6: HeadingComponent = makeTypographyComponent("h6");
export const P = makeTypographyComponent("body1");

export const Code: CodeComponent = (props) => {
  const contextProps = useTypography();
  
  return (
    <Typography
      {...contextProps}
      component="span"
      sx={{ fontFamily: monospace }}
      {...props}
    />
  );
};

export const HR = () => (
  <Divider
    sx={{
      mx: {
        xs: -2,
        sm: -3,
        md: -4,
      },
      my: 2,
    }}
  />
);

export const Img = styled("img")({
  maxWidth: "100%",
  borderRadius: 8,
});

export const components: Components = {
  // Typography
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  p: P,
  code: Code,
  // Table
  table: Table,
  thead: TableHead,
  tbody: TableBody,
  tr: TableRow,
  // @ts-ignore
  th: TableCell,
  // @ts-ignore
  td: TableCell,
  // Other
  a: Link,
  hr: HR,
  // @ts-ignore
  img: Img,
};

import { InputBase, Stack } from "@mui/material";
import { monospace } from "../../styles/typography";
import { useNotes } from "../../contexts/Notes/NotesContext";
import Sheet from "../Sheet/Sheet";

export interface EditorProps {
  noteId: string;
}

const Editor: React.FC<EditorProps> = ({ noteId }) => {
  const { getNote, updateNote } = useNotes();

  const note = getNote(noteId);

  return (
    <Sheet sx={{ flexGrow: 1 }}>
      <Stack sx={{ height: "100%" }}>
        <InputBase
          value={note?.title}
          onChange={(event) => updateNote(noteId, {
            title: event.currentTarget.value,
          })}
          placeholder="Untitled"
          sx={{
            pt: 0,
            pb: 0,
            fontSize: {
              xs: "3.5rem",
              sm: "4.7129rem",
              md: "5.3556rem",
              lg: "6rem",
            },
          }}
        />
        <InputBase
          fullWidth
          multiline
          value={note?.text}
          onChange={(event) => updateNote(noteId, {
            text: event.currentTarget.value,
          })}
          placeholder="Start typing..."
          sx={{
            flexGrow: 1,
            alignItems: "start",
            fontFamily: monospace,
          }}
        />
      </Stack>
    </Sheet>
  );
};

export default Editor;

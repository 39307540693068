import { createTheme, responsiveFontSizes } from "@mui/material";
import { grey } from "@mui/material/colors";
import { deepmerge } from "@mui/utils";
import { primary, secondary } from "../colors";
import { base } from "./base";

export const lightTheme = responsiveFontSizes(createTheme(deepmerge(base, {
  palette: {
    mode: "light",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {
          color: grey[900],
        },
        outlinedPrimary: {
          color: primary,
        },
        outlinedSecondary: {
          color: secondary,
        },
        text: {
          color: grey[900],
        },
        textPrimary: {
          color: primary,
        },
        textSecondary: {
          color: secondary,
        },
      },
    },
  },
})));

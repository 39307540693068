import { useParams } from "react-router-dom";
import Screen from "../../widgets/Screen/Screen";
import Viewer from "../../widgets/Viewer/Viewer";

const ViewNote: React.FC = () => {
  const { id } = useParams();

  return (
    <Screen>
      {id && (
        <Viewer noteId={id} />
      )}
    </Screen>
  );
};

export default ViewNote;

import { MenuItem, MenuItemProps, SxProps, Typography, useTheme } from "@mui/material";
import React from "react";
import { Note } from "../../contexts/Notes/NotesContext";

export type NoteListingProps =
  & Omit<MenuItemProps, "children">
  & {
    note: Note;
  };

const NoteListing: React.FC<NoteListingProps> = ({
  note,
  onSelect,
  ...props
}) => {
  const { palette } = useTheme();

  const selectedBackground = palette.primary.main;
  const selectedForeground = palette.getContrastText(selectedBackground);

  const sx: SxProps = props.selected ? {
    backgroundColor: `${selectedBackground} !important`,
    color: selectedForeground,
  } : {};

  return (
    <MenuItem
      {...props}
      sx={sx}
    >
      <Typography noWrap>
        {note.title || "Untitled"}
      </Typography>
    </MenuItem>
  );
};

export default NoteListing;

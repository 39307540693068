import { ThemeOptions } from "@mui/material";
import { cyan, yellow } from "@mui/material/colors";

export const black = "#000";
export const white = "#fff";

export const primary = yellow.A100;
export const secondary = cyan[900];

export const colors: ThemeOptions = {
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
  },
};

import { Add, Menu } from "@mui/icons-material";
import { Box, Fab, IconButton, MenuItem, MenuList, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNotes } from "../../contexts/Notes/NotesContext";
import { useScreen } from "../../contexts/Screen/ScreenContext";
import NoteListing from "../NoteListing/NoteListing";

const NotesList: React.FC = () => {
  const { listNotes, createNote, currentNote, setCurrentNote } = useNotes();
  const notes = listNotes();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("lg"));
  const { sidebar } = useScreen();

  return (
    <Stack
      sx={{
        py: 2,
        height: "100%",
      }}
    >
      <Typography
        variant="overline"
        color="text.secondary"
        sx={{ px: 2 }}
      >
        Local notes
      </Typography>
      <MenuList
        disablePadding
        sx={{
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        {notes?.map((note) => (
          <NoteListing
            note={note}
            onClick={() => setCurrentNote(note.id)}
            selected={note.id === currentNote}
            key={note.id}
          />
        ))}
        {!notes?.length && (
          <MenuItem disabled>
            None
          </MenuItem>
        )}
      </MenuList>
      <Stack
        direction="row"
        sx={{
          px: 3,
          py: 1,
        }}
      >
        {isMobile && (
          <IconButton onClick={() => sidebar.toggle(false)}>
            <Menu />
          </IconButton>
        )}
        <Box flexGrow={1} />
        <Fab
          variant="extended"
          color="primary"
          onClick={createNote}
        >
          <Add sx={{ mr: 1 }} />
          New
        </Fab>
      </Stack>
    </Stack>
  )
};

export default NotesList;

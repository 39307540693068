import { createContext, useContext } from "react";
import { useToggle } from "react-use";

export interface ScreenContextValue {
  sidebar: {
    isOpen: boolean;
    isProminent: boolean;
    toggle: (open?: boolean) => void;
  };
}

export const ScreenContext = createContext<ScreenContextValue | null>(null);

export type ScreenContextProviderProps =
  & React.PropsWithChildren
  & {
    prominentSidebar?: boolean;
  };

export const ScreenContextProvider: React.FC<ScreenContextProviderProps> = ({
  prominentSidebar = false,
  children,
}) => {
  const [sidebarOpen, setSidebarOpen] = useToggle(prominentSidebar);

  const value: ScreenContextValue = {
    sidebar: {
      isOpen: sidebarOpen,
      isProminent: prominentSidebar,
      toggle: setSidebarOpen,
    },
  };

  return (
    <ScreenContext.Provider value={value}>
      {children}
    </ScreenContext.Provider>
  );
};

export const useScreen = () => {
  const context = useContext(ScreenContext);
  if (!context) throw new Error("No screen context provided.");
  return context;
};

import { ChevronLeft, Edit, MenuRounded, MoreVert, Visibility } from "@mui/icons-material";
import { Box, Button, Container, IconButton, Menu, MenuItem, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useNotes } from "../../contexts/Notes/NotesContext";
import { useScreen } from "../../contexts/Screen/ScreenContext";
import Editor from "../Editor/Editor";
import Sheet from "../Sheet/Sheet";
import { components } from "./components";

export interface ViewerProps {
  noteId: string;
}

const Viewer: React.FC<ViewerProps> = ({ noteId }) => {
  const { getNote, deleteNote, setCurrentNote } = useNotes();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("lg"));
  const { sidebar } = useScreen();

  const note = getNote(noteId);

  const [isEditing, setIsEditing] = useState(!note.text);
  const isViewing = !isEditing;

  const [menuOpen, setMenuOpen] = useState(false);
  const menuButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <Container
      sx={{
        height: "100%",
        position: "relative"
      }}
    >
      <Stack
        sx={{
          height: "100%",
          gap: 2,
        }}
      >
        {isViewing && (
          <Sheet sx={{ flexGrow: 1 }}>
            <Typography
              variant="h1"
              sx={{
                textAlign: "center",
              }}
            >
              {note?.title || "Untitled"}
            </Typography>
            <ReactMarkdown components={components}>
              {note?.text || ""}
            </ReactMarkdown>
          </Sheet>
        )}
        {isEditing && (
          <Editor noteId={noteId} />
        )}
        <Stack
          direction="row"
          sx={{
            alignItems: "center",
            gap: {
              xs: 1,
              sm: 2,
            },
          }}
        >
          <IconButton onClick={() => {
            setCurrentNote(null);
          }}>
            <ChevronLeft />
          </IconButton>
          {isMobile && (
            <IconButton onClick={() => sidebar.toggle()}>
              <MenuRounded />
            </IconButton>
          )}
          <Box sx={{ flexGrow: 1 }} />
          {isViewing && (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Edit />}
              onClick={() => setIsEditing(true)}
            >
              Edit
            </Button>
          )}
          {isEditing && (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Visibility />}
              onClick={() => setIsEditing(false)}
            >
              Preview
            </Button>
          )}
          <IconButton
            onClick={() => setMenuOpen(!menuOpen)}
            ref={menuButtonRef}
          >
            <MoreVert />
          </IconButton>
        </Stack>
      </Stack>
      <Menu
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        anchorEl={menuButtonRef.current}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={() => deleteNote(noteId)}>
          Delete
        </MenuItem>
      </Menu>
    </Container>
  );
};

export default Viewer;
